<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">
        新增
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column label="会员名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="有效时长(月)" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.effective_duration_num }}
        </template>
      </el-table-column>

      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>

      <el-table-column label="购买人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.buy_num }}
        </template>
      </el-table-column>

      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="会员名:" prop="name">
          <el-input type="text" v-model="form.name" maxlength="4" clearable />
        </el-form-item>
        <el-form-item label="有效时长(月):" prop="effective_duration_num">
          <el-input
            type="text"
            v-model="form.effective_duration_num"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
            clearable
          />
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input
            v-model="form.price"
            style="width: 200px"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          ></el-input>
        </el-form-item>
        <el-form-item label="文案" prop="introduce">
          <el-input
            type="text"
            v-model="form.introduce"
            maxlength="6"
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        // category_id: 2,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        price: "",
        introduce: "",
        name: "",
        effective_duration_num: "",
      },
      rules: {
        price: [
          { required: true, message: "会员价格不能为空", trigger: "blur" },
        ],
        introduce: [
          { required: true, message: "文案不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "会员名称不能为空", trigger: "blur" },
        ],
        effective_duration_num: [
          { required: true, message: "有效时长不能为空", trigger: "blur" },
        ],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/vipPackage/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        category_id: 2,
        cover_url: "",
        weight: 1,
        link_type: 0,
        link: "",
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      // if (this.form.link_type && !this.form.link) {
      //   this.$message({
      //     type: "error",
      //     message: "跳转链接不能为空",
      //   });
      //   return;
      // }
      // if (!this.form.link_type) {
      //   this.form.link = "";
      // }
      if (this.form.effective_duration_num == 0) {
          this.$message({
            type: "error",
            message: "会员有效时长请大于或等于1",
          });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/vipPackage/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/vipPackage/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该课程类型, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/vipPackage/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
